import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Button } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import { graphql } from "gatsby";

import {
  Article,
  GridImages,
  Hero,
  HeroProject,
  Link,
  Page,
  Section,
  SectionMedia,
  usePageStyles,
} from "components";
import { FormContact } from "containers";

// TODO:
// - create images container to properly regex relativeDirectory using location path
// - contact form container should have the hero inside it
// - contact form container should be customizable for different page styles
// - create SectionArticle to configure article layout
// - create FormSection or add hero to contact form container
// - replace HeroProject component with reusable HeroArticle component

const TemplateProject = ({
  data: {
    project: {
      body,
      frontmatter: {
        category,
        date,
        description,
        form,
        header,
        hero,
        image,
        location,
        page,
        seo,
        tags,
        title,
        type,
      },
    },
    projectImages,
  },
  location: { href, origin, pathname },
}) => {
  const styles = usePageStyles();

  const seoOptions = {
    description,
    image,
    title: `${title} | ${category}`,
    ...seo,
    openGraph: {
      url: `${href}`,
      images: [
        {
          url: `${origin}/${image.childImageSharp.fluid.src}`,
          width: 1080,
          height: 720,
          alt: title,
        },
      ],
    },
  };

  const pageOptions = {
    header: {
      color: "secondary",
    },
    ...page,
  };

  const headerOptions = {
    context: `Published on ${date}`,
    heading: title,
    image,
    ...header,
  };

  // TODO: add hero options to contact form container to avoid this messy config
  const heroOptions = {
    bgcolor: "secondary.light",
    context:
      form && form.context
        ? form.context
        : `Looking for commercial and residential ${category.toLowerCase()} services for ${type.toLowerCase()} in ${location} or nearby areas?${
            tags &&
            ` We're offering expert level ${tags.map(
              (tag) => ` ${tag.toLowerCase()}`
            )}, and much more at competitive prices.`
          } Fill out your name, email and a few details about your ${category.toLowerCase()} needs, and we'll handle the rest.`,
    heading:
      form && form.heading
        ? form.heading
        : `Professional ${category.toLowerCase()} services for all types of ${type.toLowerCase()}`,
    minHeight: "none",
    ...hero,
  };

  const formOptions = {
    buttonCopy: "Let's talk about your project",
    referralProps: {
      category,
      page: pathname,
      type,
    },
    ...form,
  };

  // TODO: move all instances of renderArticle into a reusable component or hook
  const renderArticle = (pageType) => {
    switch (pageType) {
      case "media":
        return null;
      default:
        return (
          <Section>
            <Article content={body} />
          </Section>
        );
    }
  };

  return (
    <Page options={pageOptions} seo={seoOptions}>
      <HeroProject {...headerOptions}>
        {category && (
          <Button
            className={classNames(styles.button, "hero")}
            color="inherit"
            component={Link}
            size="small"
            startIcon={<ArrowBack />}
            to={`/projects/${category.split(" ").join("-").toLowerCase()}/`}
            variant="outlined"
          >
            {category} Projects
          </Button>
        )}
      </HeroProject>
      {projectImages && (
        <SectionMedia>
          <GridImages className="feature" images={projectImages} />
        </SectionMedia>
      )}
      {renderArticle(pageOptions.pageType)}
      <Hero {...heroOptions}>
        {!formOptions.isHidden && <FormContact {...formOptions} />}
      </Hero>
    </Page>
  );
};

TemplateProject.propTypes = {
  data: PropTypes.shape({
    project: PropTypes.shape({
      body: PropTypes.string,
      fields: PropTypes.shape({
        slug: PropTypes.string,
      }),
      frontmatter: PropTypes.shape({
        category: PropTypes.string,
        date: PropTypes.string,
        description: PropTypes.string,
        form: PropTypes.shape({
          buttonCopy: PropTypes.string,
          isHidden: PropTypes.bool,
        }),
        header: PropTypes.shape({
          heading: PropTypes.string,
        }),
        hero: PropTypes.shape({
          context: PropTypes.string,
          heading: PropTypes.string,
        }),
        image: PropTypes.shape({
          childImageSharp: PropTypes.shape({
            fluid: PropTypes.shape({
              aspectRatio: PropTypes.number,
              base64: PropTypes.string,
              src: PropTypes.string,
              srcSet: PropTypes.string,
              sizes: PropTypes.string,
            }),
          }),
        }),
        location: PropTypes.string,
        page: PropTypes.shape({
          header: PropTypes.shape({
            color: PropTypes.string,
          }),
          pageType: PropTypes.string,
        }),
        seo: PropTypes.shape({
          title: PropTypes.string,
        }),
        tags: PropTypes.arrayOf(PropTypes.string),
        title: PropTypes.string,
        type: PropTypes.string,
      }),
    }),
    projectImages: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            id: PropTypes.string,
            image: PropTypes.shape({
              fluid: PropTypes.shape({
                aspectRatio: PropTypes.number,
                base64: PropTypes.string,
                src: PropTypes.string,
                srcSet: PropTypes.string,
                sizes: PropTypes.string,
              }),
            }),
            name: PropTypes.string,
            relativeDirectory: PropTypes.string,
          }),
        })
      ),
    }),
  }),
};

export const projectBySlugQuery = graphql`
  query ProjectBySlug($slug: String!) {
    project: mdx(fields: { slug: { eq: $slug } }) {
      body
      fields {
        slug
      }
      frontmatter {
        category
        date(formatString: "MMMM DD, YYYY")
        description
        form {
          buttonCopy
        }
        header {
          heading
        }
        hero {
          context
          heading
        }
        image {
          childImageSharp {
            fluid(maxWidth: 1280, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        location
        page {
          pageType
        }
        seo {
          title
        }
        tags
        title
        type
      }
    }
    projectImages: allFile(
      filter: {
        extension: { regex: "/(jpg)|(jpeg)|(png)|(svg)|(tif)|(tiff)|(webp)/" }
        relativeDirectory: { regex: $slug }
      }
      sort: { order: ASC, fields: name }
    ) {
      edges {
        node {
          id
          image: childImageSharp {
            fluid(maxWidth: 1280, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          name
          relativeDirectory
        }
      }
    }
  }
`;

export default TemplateProject;
